import {
  Alignment,
  Classes,
  Colors,
  Icon,
  Navbar,
  Text,
} from "@blueprintjs/core";
import styles from "./Navbar.module.css";

export function CustomNavbar(): JSX.Element {
  return (
    <Navbar className={Classes.DARK} style={{ width: "100%" }}>
      <Navbar.Group align={Alignment.LEFT} className={styles.navbarLeft}>
        <img src="keen-ai-logo.svg" />
        <Navbar.Divider />

        <Text color={Colors.GRAY3} tagName="h2">
          intelligent corrosion assessment
        </Text>
      </Navbar.Group>

      <Navbar.Group align={Alignment.RIGHT} className={styles.navbarRight}>
        <Icon icon="user" />
        <Icon icon="notifications" />
        <Icon icon="settings" />
      </Navbar.Group>
    </Navbar>
  );
}
