import {
  Button,
  Card,
  Classes,
  Elevation,
  FormGroup,
  Intent,
} from "@blueprintjs/core";
import { userSignedInAtom } from "atoms";
import axios from "axios";
import classNames from "classnames";
import { useSetAtom } from "jotai";
import { useForm } from "react-hook-form";

import styles from "./Login.module.css";

type LoginForm = {
  username: string;
  password: string;
};

export function Login(): JSX.Element {
  const setUserSignedIn = useSetAtom(userSignedInAtom);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();

  async function onSubmit(data: LoginForm): Promise<void> {
    await axios
      .post(
        "https://europe-west2-national-grid-dev.cloudfunctions.net/ng-innovation-login",
        {
          username: data.username,
          password: data.password,
        }
      )
      .then(() => {
        setUserSignedIn(true);
      })
      .catch((res) => {
        if (res.response.status === 403) {
          setError("password", {
            message: "Incorrect username or password",
          });
        }
      });
  }

  return (
    <Card
      className={classNames(Classes.DARK, styles.loginCard)}
      elevation={Elevation.ONE}
    >
      <img src="keen-ai-logo.svg" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="Username" labelFor="username">
          <div className="bp4-input-group">
            <span className="bp4-icon bp4-icon-user" />
            <input
              id="username"
              className="bp4-input"
              {...register("username", { required: true })}
            />
          </div>
        </FormGroup>

        <FormGroup
          label="Password"
          labelFor="password"
          helperText={errors.password?.message}
          intent={Intent.DANGER}
        >
          <div className="bp4-input-group">
            <span className="bp4-icon bp4-icon-lock" />
            <input
              id="password"
              type="password"
              className="bp4-input"
              {...register("password", { required: true })}
            />
          </div>
        </FormGroup>

        <Button type="submit" fill>
          Login
        </Button>
      </form>
    </Card>
  );
}
