import { Button, ButtonGroup, Icon, Intent } from "@blueprintjs/core";
import {
  currentFilterAtom,
  currentSelectedImageAtom,
  showFullscreenModalAtom,
  visibleImagesAtom,
} from "atoms";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import styles from "./ImagePreviewPanel.module.css";

type CardHeaderType = {
  zoomIn(): void;
  zoomOut(): void;
  resetTransform(): void;
};

export function CardHeader({
  zoomIn,
  zoomOut,
  resetTransform,
}: CardHeaderType): JSX.Element {
  const [showModal, setShowModal] = useAtom(showFullscreenModalAtom);
  const [currentFilter, setFilter] = useAtom(currentFilterAtom);
  const [selectedImage, setSelectedImage] = useAtom(currentSelectedImageAtom);

  const visibleImages = useAtomValue(visibleImagesAtom);

  useEffect(() => {
    resetTransform();
  }, [selectedImage, showModal]);

  const galleryAtStart = selectedImage?.index === 0;
  const galleryAtEnd =
    visibleImages.indexOf(selectedImage.imageSrc) === visibleImages.length - 1;

  function filterImage(filter: "original" | "steel" | "corrosion"): void {
    if (currentFilter.includes(filter)) {
      setFilter(currentFilter.filter((item) => item !== filter));
    } else {
      setFilter([...currentFilter, filter]);
    }
  }

  function imageControl(delta: 1 | -1): void {
    if (
      (galleryAtStart && delta === -1) ||
      (galleryAtEnd && delta === 1) ||
      !selectedImage
    ) {
      return;
    }

    const currentIndex = visibleImages.indexOf(selectedImage.imageSrc);

    setSelectedImage({
      id: visibleImages[currentIndex + delta].slice(-25),
      imageSrc: visibleImages[currentIndex + delta],
      index: currentIndex + delta,
    });
  }

  return (
    <div className={styles.cardHeader}>
      <ButtonGroup>
        {/* <Button
          onClick={(): void => setFilter(["original"])}
          rightIcon={<Icon icon="symbol-square" color="#fff" />}
          small
        >
          Original
        </Button> */}
        <Button
          onClick={(): void => filterImage("steel")}
          intent={
            currentFilter.includes("steel") ? Intent.SUCCESS : Intent.NONE
          }
          rightIcon={<Icon icon="symbol-square" />}
          small
        >
          Steel
        </Button>
        <Button
          onClick={(): void => filterImage("corrosion")}
          intent={
            currentFilter.includes("corrosion") ? Intent.SUCCESS : Intent.NONE
          }
          rightIcon={<Icon icon="symbol-square" color="#FF0505" />}
          small
        >
          Corrosion
        </Button>
      </ButtonGroup>

      <div className={styles.cardHeaderRight}>
        <ButtonGroup>
          <Button
            onClick={(): void => zoomIn()}
            icon={<Icon icon="zoom-in" />}
          />
          <Button
            onClick={(): void => zoomOut()}
            icon={<Icon icon="zoom-out" />}
          />
          <Button
            onClick={(): void => resetTransform()}
            icon={<Icon icon="reset" />}
          />
        </ButtonGroup>

        <ButtonGroup className={styles.navigationButtons}>
          <Button
            rightIcon={<Icon icon="chevron-left" />}
            onClick={(): void => imageControl(-1)}
            disabled={galleryAtStart}
          />

          <Button
            rightIcon={<Icon icon="chevron-right" />}
            onClick={(): void => imageControl(+1)}
            disabled={galleryAtEnd}
          />

          <Button
            rightIcon={<Icon icon={showModal ? "minimize" : "maximize"} />}
            onClick={(): void => setShowModal(!showModal)}
          />
        </ButtonGroup>
      </div>
    </div>
  );
}
