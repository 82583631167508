import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { rotateCameraAtom, visibleImagesAtom } from "atoms";
import debounce from "debounce";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { Frustum, Matrix4 } from "three";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import imageMetadata from "../../imageMetadata.json";

export function Controls(): JSX.Element {
  const { scene, camera } = useThree();
  const setVisibleImages = useSetAtom(visibleImagesAtom);
  const [rotateCamera] = useAtom(rotateCameraAtom);

  const controlsRef = useRef<OrbitControlsImpl>(null);

  useEffect(() => {
    if (!controlsRef.current) {
      return;
    }

    controlsRef.current.autoRotate = rotateCamera;
    controlsRef.current.autoRotateSpeed = 1;
  }, [rotateCamera]);

  useEffect(() => {
    if (!controlsRef.current) {
      return;
    }

    const frustum = new Frustum();

    function onChange(): void {
      frustum.setFromProjectionMatrix(
        new Matrix4().multiplyMatrices(
          camera.projectionMatrix,
          camera.matrixWorldInverse
        )
      );

      // following logic filters out images that aren't in the current view
      const imagesInView = imageMetadata
        .filter((image) => {
          const sphere = scene.getObjectByName(image.filename);
          const sphereInView = sphere && frustum.intersectsObject(sphere);

          if (sphereInView) {
            return image;
          }
        })
        .map((image) => "/ZL067/original/" + image.filename);

      setVisibleImages(imagesInView);
    }

    controlsRef.current.addEventListener("change", debounce(onChange));

    return () => {
      controlsRef.current &&
        controlsRef.current.removeEventListener("change", debounce(onChange));
    };
  }, []);

  return <OrbitControls ref={controlsRef} />;
}
