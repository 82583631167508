import { atom } from "jotai";
import imageMetadata from "./imageMetadata.json";

import { atomWithCompare, atomWithToggle } from "./utils/atomWithCompare";

type SelectedImage = {
  id: string;
  index: number;
  imageSrc: string;
};

export const allImages = imageMetadata.map(
  (image) => "/ZL067/original/" + image.filename
);

export const visibleImagesAtom = atomWithCompare<string[]>(
  allImages,
  (prev, next) => {
    return prev.toString() === next.toString() ? true : false;
  }
);

export const currentSelectedImageAtom = atom<SelectedImage>({
  id: allImages[0].slice(-25),
  index: 0,
  imageSrc: allImages[0],
});

export const currentFilterAtom = atom<
  ("original" | "steel" | "corrosion" )[]
>(["original"]);

export const showFullscreenModalAtom = atomWithToggle(false);

export const spheresToggledAtom = atom<boolean>(true);
export const rotateCameraAtom = atom<boolean>(false);

export const userSignedInAtom = atom<boolean>(false);
