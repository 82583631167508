import { useLoader } from "@react-three/fiber";

import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";

// The general rule is that Fiber components are available under the
// camel-case version of their name in three.js.

export default function Model({ url }: { url: string }): JSX.Element {
  const geometry = useLoader(PLYLoader, url);
  geometry.computeVertexNormals();
  geometry.center();

  return (
    <points
      rotation={[0, 0.1, -Math.PI - 0.3]} // needed temporarily to align different model with spheres
      geometry={geometry}
      castShadow={true}
      receiveShadow={true}
    >
      <pointsMaterial size={0.01} vertexColors={true} />
    </points>
  );
}
