import { Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { clearSchematicAtom } from "../../AssessmentPanel";
import style from "./Segment.module.css";

type Props = {
  id?: string;
  path: string;
  stroke?: string;
  strokeLinejoin?: "miter" | "round" | "bevel" | "inherit" | undefined;
  defaultColour?: CorrosionColour;
};

export enum CorrosionColour {
  BLANK = "#182026",
  NONE = "#15B371",
  VERYLIGHT = "#9AE875",
  LIGHT = "#C0F330",
  MEDIUM = "#FFD703",
  HEAVY = "#A96B31",
  MISSING = "#B31515",
}

// export enum CorrosionColour {
//   BLANK = "#182026",
//   NONE = "#ADB4FF",
//   VERYLIGHT = "#707CFF",
//   LIGHT = "#0A1FFF",
//   MEDIUM = "#0011CC",
//   HEAVY = "#000A7A",
//   MISSING = "#00053D",
// }

function Segment({
  id,
  path,
  stroke,
  strokeLinejoin,
  defaultColour = CorrosionColour.BLANK,
}: Props): JSX.Element {
  const [colour, setColour] = useState<CorrosionColour>(defaultColour);

  const [clearSchematic, setClearSchematic] = useAtom(clearSchematicAtom);

  useEffect(() => {
    if (!clearSchematic) {
      return;
    }

    setColour(CorrosionColour.BLANK);
    setClearSchematic(false);
  }, [clearSchematic]);

  return (
    <Popover2
      targetTagName={"g"}
      content={
        <Menu>
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.NONE }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.NONE)}
            text="No visible corrosion"
          />
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.VERYLIGHT }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.VERYLIGHT)}
            text="Very light corrosion"
          />
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.LIGHT }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.LIGHT)}
            text="Light corrosion"
          />
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.MEDIUM }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.MEDIUM)}
            text="Medium corrosion"
          />
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.HEAVY }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.HEAVY)}
            text="Heavy corrosion"
          />
          <MenuItem
            icon={
              <Icon
                icon="symbol-square"
                style={{ color: CorrosionColour.MISSING }}
              />
            }
            onClick={(): void => setColour(CorrosionColour.MISSING)}
            text="Missing steel"
          />
        </Menu>
      }
    >
      <path
        id={id}
        className={style.segment}
        onContextMenu={(e: React.MouseEvent<SVGPathElement>): void => {
          e.preventDefault();
          setColour(CorrosionColour.BLANK);
        }}
        d={path}
        fill={colour}
        stroke={stroke}
        strokeLinejoin={strokeLinejoin}
      />
    </Popover2>
  );
}

export default Segment;
