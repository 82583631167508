import { Dialog } from "@blueprintjs/core";
import { showFullscreenModalAtom, userSignedInAtom } from "atoms";

import { useAtom, useAtomValue } from "jotai";
import { Login } from "components/Login/Login";

import { CustomNavbar } from "components/Navbar/Navbar";
import styles from "./App.module.css";

import { AssessmentPanel } from "./components/AssessmentPanel";
import ImageGalleryPanel from "./components/ImageGalleryPanel/ImageGalleryPanel";
import ImagePreviewPanel from "./components/ImagePreviewPanel/ImagePreviewPanel";
import LidarPanel from "./components/LidarPanel/LidarPanel";

const ENVIRONMENT = process.env.NODE_ENV;

function App(): JSX.Element {
  const [showModal, setShowModal] = useAtom(showFullscreenModalAtom);
  const userSignedIn = useAtomValue(userSignedInAtom);

  if (!userSignedIn && ENVIRONMENT === "production") {
    return (
      <main className={styles.main}>
        <CustomNavbar />
        <div
          className={styles.panelContainer}
          style={{ justifyContent: "center" }}
        >
          <Login />
        </div>
      </main>
    );
  }

  return (
    <main className={styles.main}>
      <CustomNavbar />

      <div className={styles.panelContainer}>
        <div className={styles.panelLeft}>
          <ImagePreviewPanel />
          <ImageGalleryPanel />
        </div>

        <div className={styles.panelMiddle}>
          <LidarPanel />
        </div>

        <div className={styles.panelRight}>
          <AssessmentPanel />
        </div>
      </div>

      <Dialog
        isOpen={showModal}
        onClose={(): void => setShowModal(false)}
        style={{ padding: 0, width: "98%", height: "96vh", margin: 0 }}
      >
        <ImagePreviewPanel modalView />
      </Dialog>
    </main>
  );
}

export default App;
