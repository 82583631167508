import Segment, { CorrosionColour } from "./Segment/Segment";

function Schematic(): JSX.Element {
  return (
    <svg
      id="schematic"
      viewBox="0 0 261 402"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Segment
        // left peak
        defaultColour={CorrosionColour.HEAVY}
        path="M120.775 0L115.723 34.9024H129.5V0H120.775Z"
      />
      <Segment
        // right peak
        defaultColour={CorrosionColour.HEAVY}
        path="M143.277 34.9024L138.225 0H129.5V34.9024H143.277Z"
      />
      <Segment
        // left body
        defaultColour={CorrosionColour.LIGHT}
        path="M115.723 34.9024L114.324 51.8944L109.86 112.974L108.149 136.395L105.161 197.474L102.503 217.222L95.5177 269.116H129.5V34.9024H115.723Z"
      />
      <Segment
        // right body
        defaultColour={CorrosionColour.LIGHT}
        path="M144.676 51.8944L143.277 34.9024H129.5V269.116H163.482L156.497 217.222L153.839 197.474L150.851 136.395L149.14 112.974L144.676 51.8944Z"
      />
      <Segment
        // left base
        defaultColour={CorrosionColour.NONE}
        path="M80.8227 315.959L95.5177 269.116H129.5V359.127L57.8617 400L80.8227 315.959Z"
      />
      <Segment
        // right base
        defaultColour={CorrosionColour.NONE}
        path="M178.177 315.959L163.482 269.116H129.5V359.127L201.138 400L178.177 315.959Z"
      />
      <Segment
        // left top arm
        defaultColour={CorrosionColour.VERYLIGHT}
        path="M114.324 51.8944L115.723 34.9024L41.3298 51.8944H70.2606H93.6064H114.324Z"
      />
      <Segment
        // right top arm
        defaultColour={CorrosionColour.LIGHT}
        path="M143.277 34.9024L144.676 51.8944H165.394H188.739H217.67L143.277 34.9024Z"
      />

      <Segment
        // left middle arm
        defaultColour={CorrosionColour.VERYLIGHT}
        path="M76.2305 136.395H108.149L109.86 112.974L0 136.395H38.1152H76.2305Z"
      />

      <Segment
        // right middle arm
        defaultColour={CorrosionColour.LIGHT}
        path="M150.851 136.395H182.77H220.885H259L149.14 112.974L150.851 136.395Z"
      />

      <Segment
        // left bottom arm
        defaultColour={CorrosionColour.VERYLIGHT}
        path="M102.503 217.222L105.161 197.474L16.0727 217.222H47.7589H76.2305H102.503Z"
      />

      <Segment
        // right bottom arm
        defaultColour={CorrosionColour.MEDIUM}
        path="M153.839 197.474L156.497 217.222H182.77H211.241H242.927L153.839 197.474Z"
      />
    </svg>
  );
}

export default Schematic;
