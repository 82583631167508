import { Canvas } from "@react-three/fiber";
import { rotateCameraAtom } from "atoms";
import { useSetAtom } from "jotai";
import { Suspense } from "react";
import CustomCard from "../CustomCard/CustomCard";
import { Controls } from "./Controls";
import { ImageSpheres } from "./ImageSpheres";

import Model from "./Model";
import { ToggleButtons } from "./ToggleButtons";

export default function LidarPanel(): JSX.Element {
  const setRotateCamera = useSetAtom(rotateCameraAtom);

  return (
    <CustomCard>
      <div style={{ height: "100%" }}>
        <ToggleButtons />

        <Canvas
          camera={{
            fov: 75,
            near: 0.1,
            far: 1000,
            position: [100, 0, 0],
            up: [0, 0, -1],
          }}
          onMouseDown={(): void => setRotateCamera(false)}
        >
          <Suspense fallback={null}>
            <ambientLight intensity={0.1} />
            <directionalLight color="red" position={[0, 0, 5]} />

            <Model url="ZL067_new.ply" />
            <ImageSpheres />
            <Controls />
          </Suspense>
        </Canvas>
      </div>
    </CustomCard>
  );
}
