import { Card, Classes, Elevation } from "@blueprintjs/core";
import classNames from "classnames";

import styles from "./CustomCard.module.css";

type Props = {
  title?: string;
  controls?: JSX.Element;
  children?: JSX.Element[] | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
};

function CustomCard({
  title,
  controls,
  children,
  className,
  style,
}: Props): JSX.Element {
  return (
    <Card
      className={Classes.DARK}
      style={{ width: "auto", height: "100%", padding: 10, flex: 1, ...style }}
      elevation={Elevation.TWO}
    >
      <div className={styles.cardContainer}>
        {(title || controls) && (
          <div className={styles.header}>
            {title && <p>{title}</p>}
            {controls}
          </div>
        )}
        <div className={classNames(styles.content, className)}>{children}</div>
      </div>
    </Card>
  );
}

export default CustomCard;
