import {
  Alignment,
  Button,
  Callout,
  FormGroup,
  HTMLSelect,
  Icon,
  Intent,
  Switch,
  TextArea,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { Tooltip2 } from "@blueprintjs/popover2";
import { allImages } from "atoms";
import { atom, useAtom } from "jotai";
import CustomCard from "../CustomCard/CustomCard";
import Schematic from "../Schematic/Schematic";

import style from "./AssessmentPanel.module.css";

export const clearSchematicAtom = atom(false);

export function AssessmentPanel(): JSX.Element {
  const [_, setClearSchematic] = useAtom(clearSchematicAtom);

  return (
    <CustomCard className={style.assessmentPanel}>
      <Callout className={style.infoPanelGrid}>
        <div className={style.titlesLeft}>
          <p>tower</p>
          <p>recorded</p>
          <p>site</p>
        </div>
        <div className={style.infoLeft}>
          <p>ZL067</p>
          <p>2023-03-13</p>
          <p>Clay Quarry Wood</p>
        </div>

        <div className={style.titlesRight}>
          <p>nomo</p>
          <p>images</p>
          <p>assessor</p>
        </div>
        <div className={style.infoRight}>
          <p>H676/2</p>
          <p>{allImages.length}</p>
          <p>N/A</p>
        </div>
      </Callout>

      <Callout className={style.schematicGrid}>
        <Button
          className={style.schematicGridResetBtn}
          onClick={(): void => setClearSchematic(true)}
          rightIcon={<Icon icon="reset" />}
        />

        <Tooltip2 content={<span>COVENTRY - RATCLIFFE ON SOAR</span>}>
          <img src="circuit-left.svg" alt="SVG as an image" />
        </Tooltip2>

        <Schematic />

        <Tooltip2 content={<span>HAMS HALL - WILLINGTON EAST</span>}>
          <img src="circuit-right.svg" alt="SVG as an image" />
        </Tooltip2>
      </Callout>

      <FormGroup style={{ margin: 0 }}>
        <div className={style.formGrid}>
          <div className={style.controlsLeft}>
            <DateInput
              formatDate={(date): string => date.toLocaleDateString()}
              parseDate={(str): Date => new Date(str)}
              placeholder="Survey Date"
              highlightCurrentDay={true}
              fill
            />
            <HTMLSelect fill={true} defaultValue="">
              <option value="">Priority</option>
              <option value="0">High</option>
              <option value="1">Medium</option>
              <option value="2">Low</option>
            </HTMLSelect>
            <HTMLSelect fill={true} defaultValue="">
              <option value="">Vegetation</option>
              <option value="0">High</option>
              <option value="1">Medium</option>
              <option value="2">Low</option>
            </HTMLSelect>
            <HTMLSelect fill={true} defaultValue="">
              <option value="">Algae</option>
              <option value="0">High</option>
              <option value="1">Medium</option>
              <option value="2">Low</option>
            </HTMLSelect>
          </div>
          <div className={style.controlsRight}>
            <Switch
              label="Bare Steel"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
            <Switch
              label="Bird Nests"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
            <Switch
              label="Missing Stepbolts"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
            <Switch
              label="Missing Bracings"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
            <Switch
              label="Bent Bracing"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
            <Switch
              label="Foreign Objects"
              alignIndicator={Alignment.RIGHT}
            ></Switch>
          </div>
        </div>

        <TextArea
          fill={true}
          small={true}
          style={{ resize: "none" }}
          placeholder="Comment"
        />

        <Button style={{ marginTop: "12px" }} intent={Intent.SUCCESS}>
          Submit
        </Button>
      </FormGroup>
    </CustomCard>
  );
}
