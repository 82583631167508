import { Button, ButtonGroup, Icon, Intent } from "@blueprintjs/core";
import { rotateCameraAtom, spheresToggledAtom } from "atoms";
import { useAtom } from "jotai";
import styles from "./LidarPanel.module.css";

export function ToggleButtons(): JSX.Element {
  const [spheresToggled, setSpheresToggled] = useAtom(spheresToggledAtom);
  const [rotateCamera, setRotateCamera] = useAtom(rotateCameraAtom);

  return (
    <ButtonGroup className={styles.buttons}>
      <Button
        rightIcon={
          <Icon
            icon="symbol-circle"
            intent={spheresToggled ? Intent.SUCCESS : Intent.NONE}
          />
        }
        onClick={(): void => setSpheresToggled(!spheresToggled)}
      ></Button>
      <Button
        rightIcon={
          <Icon
            icon="refresh"
            intent={rotateCamera ? Intent.SUCCESS : Intent.NONE}
          />
        }
        onClick={(): void => setRotateCamera(!rotateCamera)}
      ></Button>
    </ButtonGroup>
  );
}
