import { useThree } from "@react-three/fiber";
import {
  currentSelectedImageAtom,
  spheresToggledAtom,
  visibleImagesAtom,
} from "atoms";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import imageMetadata from "../../imageMetadata.json";

export function ImageSpheres(): JSX.Element {
  const [selectedImage, setSelectedImage] = useAtom(currentSelectedImageAtom);
  const spheresToggled = useAtomValue(spheresToggledAtom);
  const visibleImages = useAtomValue(visibleImagesAtom);

  const { scene } = useThree();

  // TODO: figure out how to calc this offset automatically
  // currently it is derived by looking at the boundingSphere data for the specified .ply
  const offset = {
    x: 20,
    y: 4,
    z: -18,
  };

  // Show/hide spheres
  useEffect(() => {
    imageMetadata.forEach((image) => {
      const sphere = scene.getObjectByName(image.filename);
      if (sphere !== undefined) {
        sphere.visible = spheresToggled;
      }
    });
  }, [spheresToggled]);

  function sphereToSelectedImage(imageFilename: string, index: number): void {
    if (imageFilename === selectedImage.id) {
      setSelectedImage({
        id: visibleImages[0].slice(-25),
        imageSrc: visibleImages[0],
        index: 0,
      });
    } else {
      setSelectedImage({
        id: imageFilename,
        imageSrc: `/ZL067/original/${imageFilename}`,
        index,
      });
    }
  }

  return (
    <>
      {imageMetadata.map((image, index) => {
        return (
          <mesh
            name={image.filename}
            key={index}
            onPointerEnter={(): string =>
              (document.body.style.cursor = "pointer")
            }
            onPointerLeave={(): string => (document.body.style.cursor = "auto")}
            onClick={(): void => sphereToSelectedImage(image.filename, index)}
            position={[
              image.x - offset.x,
              image.y - offset.y,
              image.z - offset.z,
            ]}
          >
            <sphereGeometry args={[0.3, 16, 16]} />
            <meshBasicMaterial
              color={selectedImage.id === image.filename ? "#62d96b" : "red"}
            />
          </mesh>
        );
      })}
    </>
  );
}
