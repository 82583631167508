import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import { currentFilterAtom, currentSelectedImageAtom } from "atoms";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import CustomCard from "../CustomCard/CustomCard";
import { CardHeader } from "./CardHeader";

import styles from "./ImagePreviewPanel.module.css";

function ImagePreviewPanel({
  modalView,
}: {
  modalView?: boolean;
}): JSX.Element {
  const selectedImage = useAtomValue(currentSelectedImageAtom);
  const currentFilter = useAtomValue(currentFilterAtom);

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform }): JSX.Element => (
        <CustomCard
          controls={
            <CardHeader
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              resetTransform={resetTransform}
            />
          }
          style={{ height: modalView ? "100%" : "49%" }}
        >
          <div
            className={classNames(styles.imagePreviewPanel, {
              [styles.imagePreviewPanelModal]: modalView,
            })}
          >
            <TransformComponent
              wrapperStyle={{ height: "100%", width: "100%" }}
              contentStyle={{ height: "100%", width: "100%" }}
            >
              <img
                src={selectedImage?.imageSrc + ".JPG"} // TODO JPG
                style={{
                  filter: currentFilter.length > 1 ? "brightness(45%)" : "none",
                }}
              />

              <img
                src={`/ZL067/steel/${selectedImage?.id}.png`}
                style={{
                  display: currentFilter.includes("steel") ? "block" : "none",
                }}
              />

              <img
                src={`/ZL067/corrosion/${selectedImage?.id}.png`}
                style={{
                  display: currentFilter.includes("corrosion")
                    ? "block"
                    : "none",
                }}
              />
            </TransformComponent>
          </div>
        </CustomCard>
      )}
    </TransformWrapper>
  );
}

export default ImagePreviewPanel;
