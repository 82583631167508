/* eslint-disable @typescript-eslint/no-unused-vars */
import { Colors } from "@blueprintjs/core";
import { allImages, currentSelectedImageAtom, visibleImagesAtom } from "atoms";
import { useAtom } from "jotai";
import { useEffect } from "react";
import CustomCard from "../CustomCard/CustomCard";

import styles from "./ImageGalleryPanel.module.css";

function ImageGalleryPanel(): JSX.Element {
  const [visibleImages] = useAtom(visibleImagesAtom);
  const [selectedImage, setSelectedImage] = useAtom(currentSelectedImageAtom);

  useEffect(() => {
    document
      .getElementById(selectedImage.id)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [selectedImage, visibleImages]);

  return (
    <CustomCard style={{ height: "49%" }}>
      <div className={styles.imageGalleryPanel}>
        {visibleImages.map((imageSrc, index) => (
          <img
            id={imageSrc.slice(-25)}
            key={index}
            src={imageSrc + ".JPG"} // TODO jpg
            onClick={(): void =>
              setSelectedImage({ id: imageSrc.slice(-25), imageSrc, index })
            }
            style={{
              display: allImages.includes(selectedImage.imageSrc)
                ? "block"
                : "none",
              border:
                selectedImage?.imageSrc.slice(-25) === imageSrc.slice(-25)
                  ? `2.5px solid ${Colors.FOREST5}`
                  : "2.5px solid transparent",
            }}
          />
        ))}
      </div>
    </CustomCard>
  );
}

export default ImageGalleryPanel;
